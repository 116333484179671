import AddLevel from "../views/superAdmin/AddLevel";
import LevelDetails from "../views/superAdmin/LevelDetails";
import AddQuestion from "../views/superAdmin/AddQuestion";
import EditQuestion from "../views/superAdmin/EditQuestion";
import EditLevel from "../views/superAdmin/EditLevel";
import Levels from "../views/superAdmin/Levels";

const routes = [
    {
        path: "/levels/new",
        name: "Add Level",
        icon: "fas fa-table text-primary",
        component: AddLevel,
        layout: "/admin",
        display: false
    }, {
        path: "/levels/:lName",
        name: "Level Details",
        icon: "fas fa-table text-primary",
        component: LevelDetails,
        layout: "/admin",
        display: false
    }, {
        path: "/levels/:lName/add-question",
        name: "Add Question",
        icon: "fas fa-table text-primary",
        component: AddQuestion,
        layout: "/admin",
        display: false
    }, {
        path: "/levels/:lName/edit-question/:questionIndex",
        name: "Edit Question",
        icon: "fas fa-table text-primary",
        component: EditQuestion,
        layout: "/admin",
        display: false
    }, {
        path: "/levels/:lName/edit",
        name: "Edit Level",
        icon: "fas fa-table text-primary",
        component: EditLevel,
        layout: "/admin",
        display: false
    }, {
        path: "/levels",
        name: "Levels Info",
        icon: "fas fa-table text-primary",
        component: Levels,
        layout: "/admin",
        display: true
    },
];
export default routes;
